import React from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const streamingCloudKafkaSre = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Streaming Cloud Kafka SRE Lead in New York, London, Athens, Munich and Madrid. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Streaming Cloud Kafka SRE Lead,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/1iB8Ed5K1cwyfR2oZqmnrj/02342791063633ec263b9fa1d93be4e4/streaming-cloud-kafka-sre.jpeg',
      },
    },
    title: {
      title: 'Streaming Cloud Kafka SRE lead',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Streaming Cloud Kafka SRE (Lead)</h1>
          <p className="meta">New York, London, Munich, Athens, Madrid </p>
          <section className="section section--text">
            <WhoWeAre />
            <h5 className="pt-3">What your day-to-day looks like:</h5>
            <p>
              This is a brand new team, reporting to the Director of Streaming
              Cloud, which will be responsible for operating and growing the
              DataOps Cloud for streaming data. You will:
            </p>
            <ul className="pl-4 ">
              <li>
                Lead a team of highly skilled Kafka SREs managing global
                deployments of Kafka, reporting to the Director of Streaming
                Cloud
              </li>

              <li>
                Ensure stability of tenants Lenses and Kafka deployments,
                constantly monitoring, alerting and proactively manage thousands
                of data pipelines running via Lenses
              </li>
              <li>
                Deploy and maintain instances Lenses and Apache Kafka on demand
              </li>
              <li>
                Be a part of a team that owns the health of our product and
                ensure its end-to-end availability and performance according to
                defined SLOs
              </li>
              <li>
                Apply your strong troubleshooting skills and strategic disaster
                recovery thinking
              </li>
              <li>
                Apply software engineering and SRE principles to design, write
                and deliver software, improve availability, scalability and
                efficiency of our product
              </li>
              <li>
                You will be working extensively with Strimzi and maybe required
                to contribute back to the project as well as developing and
                maintaining other Kubernetes operators for Lenses and ancillary
                services
              </li>
              <li>
                Constantly improve our monitoring, metrics and KPIs as well as
                define and implement missing SLOs
              </li>
              <li>
                Drive blameless lessons learned, implement processes and
                automations to ensure prevention of problem recurrence and
                document the acquired knowledge sharing it among all teams.
              </li>
            </ul>
            <h5 className="pt-3">What you bring to the table:</h5>
            <p>
              We are going to spend many years working together. It’s important
              that we are compatible:
            </p>
            <ul className="pl-4 ">
              <li>
                You have experience lead a team, mentoring and helping other
                grow
              </li>
              <li>
                You are metrics driven, constantly working towards goals and
                KPIs, improving all the time
              </li>
              <li>
                You love being an SRE and are eager to learn and become better
              </li>
              <li>
                You are not afraid to ask questions, give and receive feedback
              </li>
              <li>
                You have experience in most of our primary technology stack
                (Kafka or Puslar are a must)
              </li>
              <li>
                You have strong problem-solving skills, which for us means not
                only you can solve a problem but also you can explain how you
                did it and why your solution is correct
              </li>
              <li>
                You are security, performance, and best-practice conscious
              </li>
              <li>
                You are able to take responsibilities and own projects
                (implementation or even design-wise)
              </li>
              <li>
                You like working in a team and you are able to push through
                language and cultural barriers to work with engineers all over
                the world
              </li>
              <li>You want to automate everything</li>
              <li>
                No task is beneath or above you. Documentation, support, and
                even little tasks are things we all do often. This applies even
                to our CEO!
              </li>
            </ul>
            You are passionate about:
            <ul className="pl-4 ">
              <li>Technology</li>
              <li>Customer Success</li>
              <li>Trying new tactics and messaging</li>
              <li>Being part of a team</li>
              <li>Sharing success with others</li>
              <li>Inspiring people</li>
            </ul>
            <h5 className="pt-3">Primary Technology Stack</h5>
            <p>
              Experience operating distributed streaming services such as Apache
              Kafka or Pulsar is required
            </p>
            <p>The most common parts of our current stack include:</p>
            <ul className="pl-4 ">
              <li>
                Docker & Kubernetes / Openshift including operator patterns
              </li>
              <li>ArgoCd</li>
              <li>Azure & Amazon AWS</li>
              <li>Scripting</li>
              <li>Go language</li>
              <li>Strimzi (big plus)</li>
              <li>
                Apache Kafka, Puslar or other distributed streaming technologies
                (must)
              </li>
              <li>Helm / Kustomize</li>
              <li>Java / Scala / Springboot</li>
              <li>Datadog / Prometheus / Grafana</li>
            </ul>
            <PartOfLenses />
          </section>

          <section>
            <BeYou />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default streamingCloudKafkaSre
